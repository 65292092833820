<script lang="ts" setup>
import type { Footer } from "~/models/Footer";

const appStore = useAppStore();
const footerText = ref("");

const { footerData } = defineProps<{
  footerData: Footer;
}>();
onMounted(() => {
  footerText.value = appStore.getFooterText;
});
onUnmounted(() => {
  appStore.resetFooterText();
  footerText.value = "";
});
</script>
<template>
  <footer class="footer-big">
    <div class="container-center row">
      <div
        v-for="footerItem in footerData.menu"
        :key="footerItem.title"
        class="col-xs-6 col-md-3"
      >
        <ul>
          <li>
            <span class="footer-big-title" :class="footerItem.title"
              ><i :class="`extra-${footerItem.title}`"></i>{{ footerItem.title }}</span
            >
            <ul>
              <li v-for="link in footerItem.items" :key="link.url">
                <NuxtLink
                  :to="link.url"
                  :target="link.external ? '_blank' : '_self'"
                  :title="link.name"
                  >{{ link.name }}</NuxtLink
                >
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <div class="col-xs-12 col-md-3 footer-big-social">
        <span class="footer-big-social-title">{{ footerData.socials.title }}</span>
        <ul class="footer-big-social-link">
          <li v-for="social in footerData.socials.items" :key="social.url">
            <NuxtLink
              :to="social.url"
              target="_blank"
              :aria-label="'logo ' + social.name"
              :title="'logo ' + social.name"
              ><i :class="`extra-${social.name}`"></i
              ><span>{{ social.name }}</span></NuxtLink
            >
          </li>
        </ul>

        <div v-for="app in footerData.apps" :key="app.title" class="footer-big-app">
          <span class="footer-big-app-title">{{ app.title }}</span>
          <ul class="footer-big-app-list">
            <li v-for="store in app.items" :key="store.url">
              <NuxtLink :to="store.url" target="_blank" rel="nofollow">
                <img :src="store.src" :alt="store.alt" loading="lazy" />
              </NuxtLink>
            </li>
          </ul>
        </div>
        <div class="footer-big-company">
          <NuxtLink :to="footerData.company.contact.url" :no-utm="false">{{
            footerData.company.contact.name
          }}</NuxtLink>
          <span class="separator">|</span>
          <NuxtLink :to="footerData.company.joinUs.url" target="_blank" rel="nofollow"
            >{{ footerData.company.joinUs.name }}
          </NuxtLink>
          <span class="separator">|</span>
          <NuxtLink :to="footerData.company.press.url"
            >{{ footerData.company.press.name }}
          </NuxtLink>
        </div>

        <div class="footer-big-cgu">
          <NuxtLink :to="footerData.legals.cgu.url">{{
            footerData.legals.cgu.name
          }}</NuxtLink
          ><span class="separator">|</span>
          <NuxtLink :to="footerData.legals.confidentiality.url">{{
            footerData.legals.confidentiality.name
          }}</NuxtLink>
        </div>
      </div>
      <div class="col-xs-12">
        <p v-if="footerText" class="legals" v-html="footerText" />
      </div>
    </div>
  </footer>
</template>

<style lang="scss" scoped>
.footer-big {
  display: flex;
  flex-wrap: wrap;
  align-items: self-start;
  justify-content: space-evenly;
  min-height: 300px;
  font-family: "Avenir";
  background:
    url("/img/filigrane-logo-extracadabra.png") bottom right no-repeat,
    var(--showcase-color-secondary-300);
  color: #fff;
  padding: var(--showcase-size-200);
  @include mq(desktop) {
    flex-wrap: nowrap;
  }
  &-title {
    font-weight: 800;
    text-transform: uppercase;
    border-bottom: 2px solid var(--showcase-color-additional-dark);
    padding-bottom: var(--showcase-size-025);
    display: flex;
    width: fit-content;
    padding-left: var(--showcase-size-150);
    margin-bottom: var(--showcase-size-100);
    position: relative;
    i {
      position: absolute;
      left: 0;
    }
    & + ul {
      li {
        margin-bottom: var(--showcase-size-050);
        font-size: 14px;
        position: relative;
        padding-left: var(--showcase-size-100);
        &:before {
          content: "";
          width: 8px;
          height: 8px;
          border-radius: 8px;
          background-color: red;
          position: absolute;
          left: 0;
          top: 2px;
        }
        a {
          text-decoration: none;
          color: var(--showcase-color-additional-light);
          line-height: 20px;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
    &.jobs {
      border-color: var(--showcase-color-others-orange);
      & + ul {
        li {
          &:before {
            background-color: var(--showcase-color-others-orange);
          }
        }
      }
    }
    &.candidat {
      border-color: var(--showcase-color-primary-200);
      & + ul {
        li {
          &:before {
            background-color: var(--showcase-color-primary-200);
          }
        }
      }
    }
    &.recrutement {
      border-color: var(--showcase-color-secondary-200);
      & + ul {
        li {
          &:before {
            background-color: var(--showcase-color-secondary-200);
          }
        }
      }
    }
  }
  &-social {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    @include mq(desktop) {
      justify-content: flex-start;
    }
    &-title {
      font-size: 13px;
      margin-bottom: var(--showcase-size-100);
      display: flex;
    }
    &-link {
      display: flex;
      width: 100%;
      justify-content: center;
      @include mq(desktop) {
        justify-content: flex-start;
      }
      li {
        margin-right: var(--showcase-size-100);
        a {
          text-decoration: none;
          font-size: 26px;
          span {
            display: none;
          }
          &:hover {
            i {
              &:before {
                color: var(--showcase-color-grey-200);
              }
            }
          }
        }
      }
    }
  }
  &-app {
    margin-top: var(--showcase-size-150);
    &-title {
      text-transform: uppercase;
      font-size: 11px;
      margin-bottom: var(--showcase-size-050);
      display: flex;
      justify-content: center;
      @include mq(desktop) {
        justify-content: flex-start;
      }
    }
    &-list {
      display: flex;
      justify-content: space-around;
      img {
        max-width: 90%;
      }
    }
  }
  &-cgu,
  &-company {
    margin-top: var(--showcase-size-150);
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    @include mq(desktop) {
      justify-content: flex-start;
    }
    .separator {
      display: flex;
      margin: 0 var(--showcase-size-050);
    }
    a {
      color: var(--showcase-color-additional-light);
      text-decoration: none;
      font-size: 12px;
    }
  }

  &-cgu {
    margin-top: var(--showcase-size-100);
  }
}
</style>
